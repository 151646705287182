/**
 * Created by Eguana
 */
require([
    'jquery',
    'matchMedia',
    'domReady!',
], function ($, mediaCheck) {
    /**
     * Right Sticky Menu
     * @author Hyuna Ju
     * @version 1.0
     */
    mediaCheck({
        media: '(min-width: 768px)',
        entry: function () {
            if ($('header').offset() && $('footer').offset()) {
                $(window).scroll(function () {
                    stickyRight();
                });
            }
        }
    });

    var stickyRight = function () {
        var scrollTop = $(window).scrollTop();
        var stickyNavTop = $('header').offset().top + 150;
        var stickyNavBottom = $('footer').offset().top - 700;
        var miniCart = $('[data-block=\'minicart\']');
        var wishlist = $('.wishlist-links');

        if (scrollTop > stickyNavTop && scrollTop < stickyNavBottom) {
            $('.sticky-menu').prepend(miniCart);
            $('.sticky-menu').prepend(wishlist);
            $('.sticky-menu').addClass('active');
        } else {
            $('.panel.header > .header.links').after(wishlist);
            wishlist.after(miniCart);
            $('.sticky-menu').removeClass('active');
        }
    };

    $('.minicart-wrapper a').on('click', function () {
        if ($(this).closest('.sticky-menu').length) {
            window.location.href = $(this).attr('href');
        }
    });

    /**
     * Top Button
     * @author Hyuna Ju
     * @version 1.0
     */
    $('.back-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    $('.panel.wrapper > .header-left').clone().appendTo('#store\\.menu');
    $('.footer.content > .footer.left').clone().appendTo('#store\\.menu');

    /**
     * Mobile Navigation
     * @author Christine Song
     * @version 1.0
     */
    mediaCheck({
        media: '(max-width: 767px)',
        entry: function () {
            if ($('.common-nav').offset()) {
                var currentNav = $('.common-nav .nav.items .current').html();
                var navTitle = $('.nav.items').parents().closest('div.block').find('.title');

                if ($('.common-nav-title').children('a').length === 0) {
                    navTitle.html(currentNav);
                }

                $('.common-nav > .title').on('click', function () {
                    $(this).next().slideToggle('slow');
                });
            }
        }
    });
});